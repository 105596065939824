import React from "react"
import Layout from "../components/layout"
import "./team.css"
import Contact from "../components/contact"
import About from "../components/about"
import TeamSection from "../components/teamSection"

export default () => {
  const lang = "en"
  return (
    <Layout
      lang={lang}
      pageUrl="/team"
      seoTitle="Team: our web and digital experts"
      seoDescription="From software engineers and product visionaries to design geeks, discover our coffee fueled A-Team."
    >
      <div id="about-background">
        <About lang={lang} />
      </div>
      <div id="team-background">
        <TeamSection lang={lang} />
      </div>
      <div id="contact-team-background">
        <Contact lang={lang} />
      </div>
    </Layout>
  )
}
